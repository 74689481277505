





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getNavigationLists } from '../api'
import { CateGory } from '../models'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    contentBox
  }
})
export default class CategoryList extends Vue {
  @Prop({ default: '' }) type!: string
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderCategoryList()
  }

  categoryStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  isLoading: boolean = true
  categoryList: Array<CateGory> = []

  mounted() {
    window.scrollTo(0, 0)
    this.renderCategoryList()
  }

  get isHaveData() {
    return this.categoryList.length
  }

  renderCategoryList() {
    this.isLoading = true
    getNavigationLists({
      'only_leaf': false
    }).then(data => {
      this.categoryList = data
      this.isLoading = false
    }).catch(e => {
      this.categoryList = []
      this.isLoading = false
      console.error('getNavigationLists = ', e)
    })
  }

  getActiveClass(item: CateGory) {
    if (!item.nodes || !item.nodes.length) {
      return {
        active: true,
        cursor: true
      }
    }
  }

  toList(item: CateGory) {
    if (!item.nodes || !item.nodes.length) {
      this.$router.push({ 
        name: 'categoryContentList',
        params: { 
          category_id: item.id
        }
      })
    }
  }
}
